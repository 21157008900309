<template><br>

  <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
<template #atag><a></a></template>
<template #content>

<section id="hero-animated" class="hero-animated d-flex align-items-center">
    <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">


      <h2><span>Info Area</span></h2>
      <p>{{ $t('downloadopis') }}</p>
   
    </div>

</section>

  <section id="featured-services" class="featured-services">
      <div class="container">

        <div class="row gy-4">



  
          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa fa-clock-o icon"></i></div>
              <h4><a @click="openfile(datoteke['radnovrijeme'])"  class="stretched-link">{{ $t('workingtimenaslov') }}</a></h4>
              <p>{{ $t('workingtime') }}</p>
            </div>
          </div><!-- End Service Item -->


          
          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa-solid fa-map"></i></div>
              <h4><a @click="openfile(datoteke['mapasp'])" class="stretched-link">Map of San Polo</a></h4>
              <p>{{ $t('map') }}</p>
            </div>
          </div><!-- End Service Item -->
          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa-solid fa-map"></i></div>
              <h4><a @click="openfile(datoteke['mapacolona'])" class="stretched-link">Map of Colona</a></h4>
              <p>{{ $t('map') }}</p>
            </div>
          </div><!-- End Service Item -->

          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa fa-map-marker icon"></i></div>
              <h4><a @click="openfile(datoteke['bale'])" class="stretched-link">{{ $t('bale') }}</a></h4>
              <p>{{ $t('bale1') }}</p>
            </div>
          </div><!-- End Service Item -->
          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa-solid fa-house icon"></i></div>
              <h4><a @click="openfile(datoteke['camping'])" class="stretched-link">{{ $t('camping') }}</a></h4>
              <p>{{ $t('camping1') }}</p>
            </div>
          </div><!-- End Service Item -->

          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa-solid fa-person-biking icon"></i></div>
              <h4><a @click="openfile(datoteke['bikepaths'])" class="stretched-link">{{ $t('bikepaths') }}</a></h4>
              <p>{{ $t('bikepaths1') }}</p>
            </div>
          </div><!-- End Service Item -->



          <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div class="service-item position-relative">
              <div class="icon"><i class="fa fa-map-marker icon"></i></div>
              <h4><a @click="openfile(datoteke['monperin'])" class="stretched-link">{{ $t('monperin') }}</a></h4>
              <p>{{ $t('monperin1') }}</p>
            </div>
          </div><!-- End Service Item -->


  

        </div>

      </div>
    </section><!-- End Featured Services Section --></template>
</Navigation>



</template>

<script>

import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {

  name: 'Info',
  components: {
    Navigation
  },

  data(){
    return{
      datoteke:[],
    }
  },

  mounted(){
    $('#preloader').remove();
  this.getDatoteke()
  const preloader = document.querySelector('#preloader');
  },


  methods:{

    openfile(url){
      console.log("adasd")
      window.location.href = url
      

 
//     window.open(url, '_blank');


    },

    methodThatForcesUpdate(){
      this.getDatoteke()
  },
  
    async getDatoteke() {

      await axios
        .get('/mpguest/datoteke/')
        .then(response => {
          this.datoteke = response.data
          console.log(this.datoteke)
          
        })
        .catch(error => {
          this.$root.toastnoti(this.$t('error'), error, "greska")
         
        })

    },
  }
}
</script>

<style scoped>

.hero-animated {
    padding: 50px 0 0px; 
    min-height: 30vh;
}

section {
    padding: 0px 0; 

}

</style>
