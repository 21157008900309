

axios.defaults.baseURL = 'http://127.0.0.1:8000/'
axios.defaults.baseURL = 'https://mpguest-c283362298bc.herokuapp.com/'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { IonicVue } from '@ionic/vue';
import store from './store'
import axios from 'axios'
import i18n from './i18n'

const app = createApp(App)
  .use(router)
  .use(IonicVue)
  .use(store).
  use(i18n).
  use(router).
  mount('#app')
